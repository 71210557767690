import * as React from 'react'
import { useEffect } from 'react';
import { StaticImage } from 'gatsby-plugin-image'
import scrollTo from 'gatsby-plugin-smoothscroll';
import { ComMain, AttractivenessLink, AttractivenessMapSlide } from '@components/index'
import { FeedInFunc } from '@func/index'
import IconArrow from '@svgs/icon_arrow.svg'
import Point2I3Age from '@svgs/point_2_i_3_age.svg'
import Point2I4Age from '@svgs/point_2_i_4_age.svg'
import Point2I5Age from '@svgs/point_2_i_5_age.svg'
import Anker1Txt from '@svgs/anker_1_txt.svg'
import Anker2Txt from '@svgs/anker_2_txt.svg'
import Anker3Txt from '@svgs/anker_3_txt.svg'
import Anker4Txt from '@svgs/anker_4_txt.svg'
import Anker5Txt from '@svgs/anker_5_txt.svg'

const AttractivenessPage = () => {

  useEffect(() => {
    FeedInFunc();
  }, []);

  return (
    <main className='mainAttractiveness'>
      {/* CONTENTS */}

      <ComMain
        ttl="ATTRACTIVENESS"
        subttl="東口の魅力"
        caption="1.小田原駅東口・ミナカ小田原（約340m／徒歩5分）"
        caption2="2.小田原銀座商店会（約10m／徒歩1分）"
      >
        <StaticImage
          src="../assets/images/attractiveness/main.jpg"
          alt=""
          className="_pc"
        />
        <StaticImage
          src="../assets/images/attractiveness/main_sp.jpg"
          alt=""
          className="_sp"
        />
      </ComMain>


      <AttractivenessLink />


      <section className='sec-lead'>
        <div className="c-content">

          <div className="lead-wrap">
            <div className="lead-box comFeed">
              <p className="ttl">「小田原」駅東口の魅力</p>
              <p className="lead">暮らしの豊かさを支えつづけてきた、<br className='_sp' />小田原市の中心地。</p>
              <p className="txt">古き良き城下町の面影を残す東口エリアは、<br />歴史と文化を継承しながら今なお発展をつづけ、<br className='_sp' />成熟の都市機能を有する市の要衝。<br />再開発事業などにより新たなスポットが<br className='_sp' />続々と誕生し暮らしの利便性が加速する反面、<br />美しい自然にも恵まれる東口は、その多くの魅力に<br className='_sp' />人々が思いを寄せる憧憬の地です。</p>
            </div>

            <div className="link-box">
              <button
                className="list list-1 comFeed"
                onClick={() => scrollTo('#secPoint1')}
              >
                <StaticImage
                  src="../assets/images/attractiveness/anker_1.jpg"
                  alt=""
                />
                <div className="cover"></div>
                <Anker1Txt className="num" />
                <div className="txt-cover">
                  <p className="top">POINT<br /><span>1</span></p>
                  <p className="center">遙かなる歴史を<br />今に受け継ぐ</p>
                  <p className="btm">
                    <IconArrow
                      alt=""
                    />
                  </p>
                </div>
              </button>

              <button
                className="list list-2 comFeed"
                onClick={() => scrollTo('#secPoint2')}
              >
                <StaticImage
                  src="../assets/images/attractiveness/anker_2.jpg"
                  alt=""
                />
                <div className="cover"></div>
                <Anker2Txt className="num" />
                <div className="txt-cover">
                  <p className="top">POINT<br /><span>2</span></p>
                  <p className="center">進化の歩みを<br />止めない小田原</p>
                  <p className="btm">
                    <IconArrow
                      alt=""
                    />
                  </p>
                </div>
              </button>

              <button
                className="list list-3 comFeed"
                onClick={() => scrollTo('#secPoint3')}
              >
                <StaticImage
                  src="../assets/images/attractiveness/anker_3.jpg"
                  alt=""
                />
                <div className="cover"></div>
                <Anker3Txt className="num" />
                <div className="txt-cover">
                  <p className="top">POINT<br /><span>3</span></p>
                  <p className="center">新旧の<br />彩りある施設が<br />多彩に集積</p>
                  <p className="btm">
                    <IconArrow
                      alt=""
                    />
                  </p>
                </div>
              </button>

              <button
                className="list list-4 comFeed"
                onClick={() => scrollTo('#secPoint4')}
              >
                <StaticImage
                  src="../assets/images/attractiveness/anker_4.jpg"
                  alt=""
                />
                <div className="cover"></div>
                <Anker4Txt className="num" />
                <div className="txt-cover">
                  <p className="top">POINT<br /><span>4</span></p>
                  <p className="center">自然の豊かさに<br />恵まれた地</p>
                  <p className="btm">
                    <IconArrow
                      alt=""
                    />
                  </p>
                </div>
              </button>

              <button
                className="list list-5 comFeed"
                onClick={() => scrollTo('#secPoint5')}
              >
                <StaticImage
                  src="../assets/images/attractiveness/anker_5.jpg"
                  alt=""
                />
                <div className="cover"></div>
                <Anker5Txt className="num" />
                <div className="txt-cover">
                  <p className="top">POINT<br /><span>5</span></p>
                  <p className="center">子育てしやすく<br />暮らしやすい東口</p>
                  <p className="btm">
                    <IconArrow
                      alt=""
                    />
                  </p>
                </div>
              </button>
            </div>
          </div>

        </div>
      </section>


      <div id="secPoint1"></div>
      <section className='sec-point sec-point-1'>

        <div className="ttl-wrap">
          <div className="ttl-box">
            <p className="subttl">POINT<span>1</span></p>
            <p className="ttl">遙かなる歴史を今に受け継ぐ</p>
          </div>
        </div>

        <div className="lead-wrap">
          <div className="lead-box comFeed">
            <p className="ttl-sec">戦国時代より発展・繁栄してきた<br className='_sp' />「小田原」駅東口。</p>
            <p className="lead-sec">小田原駅東口エリアは戦国時代に後北条氏の城下町として発展し、江戸時代には東海道屈指の宿場町として栄え、明治期には政財界人や文化人たちの別荘・居住地として愛されてきました。この界隈は古くから多くの人々が往来し、さまざまな交流の中で多くの文化や産業が育まれ、伝統として今も街に息づいています。</p>
          </div>

          <div className="img-box comFeed">
            <StaticImage
              src="../assets/images/attractiveness/point_1_i_1.jpg"
              alt=""
            />
            <p className="c-cap">小田原城址公園（約390m／徒歩5分）</p>
          </div>

          <div className="flex-box comFeed">
            <div className="list">
              <div className="img">
                <StaticImage
                  src="../assets/images/attractiveness/point_1_i_2.jpg"
                  alt=""
                />
                <p className="c-cap">小田原城天守閣所蔵</p>
              </div>
              <div className="icon">
                <p>戦国時代</p>
              </div>
              <p className="ttl">関東随一の「城下町」として発展</p>
            </div>
            <div className="list">
              <div className="img">
                <StaticImage
                  src="../assets/images/attractiveness/point_1_i_3.jpg"
                  alt=""
                />
                <p className="c-cap">小田原城天守閣所蔵</p>
              </div>
              <div className="icon">
                <p>江戸時代</p>
              </div>
              <p className="ttl">東海道屈指の「宿場町」として繁栄</p>
            </div>
            <div className="list">
            <div className="img">
              <StaticImage
                src="../assets/images/attractiveness/point_1_i_4.jpg"
                alt=""
              />
              <p className="c-cap">小田原清閑亭</p>
              </div>
              <div className="icon">
                <p>明治期</p>
              </div>
              <p className="ttl">著名人の別荘地として注目を集める</p>
            </div>
          </div>
        </div>

        <div className="post-town-wrap">
          <div className="lead-box comFeed">
            <p className="ttl-sec">江戸時代には宿場町として<br />小田原城の南側<br className='_sp' />（現駅東口エリア）が発展。<br />その頃より街と人の賑わいは<br className='_sp' />「小田原」駅東側に。</p>
          </div>
          <div className="flex-box comFeed">
            <div className="left">
              <p className="lead-sec">小田原の城下町の歴史は戦国時代に遡ります。東西を貫く東海道と南北を走る甲州道を軸とした区画の上に家臣の屋敷が配置され、職人や商人の町屋が立ち並び、当時より大いに賑わっていました。江戸時代に入ると東海道箱根越えの東側の玄関口として発展。参勤交代の大名や旅行客の往還に伴って本陣や脇本陣、旅籠などが数多く設けられ東海道有数の宿場町となり、その活気は明治になっても変わりませんでした。「ル・サンク小田原栄町」が立地する当時の町人町には、宿泊逗留者や旅行者に合わせ土産屋や食事・雑貨・衣料・魚屋といった商家が建ち並んでいました。</p>
              <div className="i1">
                <StaticImage
                  src="../assets/images/attractiveness/point_1_i_5.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="right">
              <div className="i2">
                <StaticImage
                  src="../assets/images/attractiveness/point_1_i_6.jpg"
                  alt=""
                />
              </div>
              <p className="note">御上洛東海道 小田原<br />(c)CAPSULE CORP./amanaimages</p>
            </div>
          </div>
          <div className="map-box comFeed">
            <p className="ttl">町人地と武家地は「小田原」駅東口に集中</p>
            <div className="img">
              <StaticImage
                src="../assets/images/attractiveness/point_1_i_7.png"
                alt=""
              />
              <p className="note">※出典元 <br className='_sp' />小田原市歴史的風致維持向上計画（第2期）江戸時代のまち割りと道路</p>
            </div>
          </div>
        </div>

      </section>


      <div id="secPoint2"></div>
      <section className='sec-point sec-point-2'>

        <div className="ttl-wrap">
          <div className="ttl-box">
            <p className="subttl">POINT<span>2</span></p>
            <p className="ttl">進化の歩みを止めない小田原</p>
          </div>
        </div>

        <div className="lead-wrap">
          <div className="lead-box comFeed">
            <p className="ttl-sec">2003年に小田原駅<br className='_sp' />東西自由連絡通路<br className='_sp' />「アークロード」が完成。<br />旧駅舎は約82年の幕を閉じ、<br />ようやく駅の東口と<br className='_sp' />西口が結ばれる。</p>
            <p className="lead-sec">小田原駅には5つの鉄道が乗り入れ、神奈川県西の鉄道駅としては最大の乗降客数を数えますが、かつては駅東西の連絡の悪さが大きな問題となっていました。わざわざ入場券を買って駅構内を通るか、かなり大回りをしないと反対側に出られませんでした。そこで2003年に完成した新しい駅舎は橋上駅舎となりアークロードが開通して、無料で東口と西口間を行き来できるようになり、東西連絡の不便さが解消されました。</p>
          </div>
          <div className="flex-box comFeed">
            <div className="list">
              <div className="img">
                <StaticImage
                  src="../assets/images/attractiveness/point_2_i_1.jpg"
                  alt=""
                />
                <p className="c-cap">小田原市提供</p>
              </div>
              <p className='ttl'>旧駅舎</p>
            </div>
            <div className="list">
              <div className="img">
                <StaticImage
                  src="../assets/images/attractiveness/point_2_i_2.jpg"
                  alt=""
                />
                <p className="c-cap">小田原市提供</p>
              </div>
              <p className='ttl'>新駅舎</p>
            </div>
          </div>
        </div>

        <div className="redevelop-wrap">
          <div className="lead-box comFeed">
            <p className="ttl-sec">「小田原」駅東口周辺は、<br />この5年間で3つの<br className='_sp' />大規模再開発事業が完了。</p>
            <p className="lead-sec">東口エリアでは2014年の「HaRuNe小田原」の開業を皮切りに、2020年の「ミナカ小田原」、2021年の「小田原三の丸ホール」の3つの大規模な再開発事業が完了し、暮らしの利便性はもちろんのこと、東口エリアの魅力がますます向上しました。小田原市では今後も「世界が憧れるまち“小田原”」をコンセプトとする将来都市像を掲げ、小田原の魅力を最大限に磨き上げ、国内外の人たちが訪れてみたい・住んでみたいと憧れ、住む人に住みつづけたいと思ってもらえる街づくりの実現に向けて取り組んでおります。</p>
          </div>

          <div className="flex-box comFeed">
            <div className="img-box">
              <StaticImage
                src="../assets/images/attractiveness/point_2_i_3.jpg"
                alt=""
              />
              <div className="icon">
                <Point2I3Age />
              </div>
            </div>
            <div className="txt-box">
              <p className="ttl">HaRuNe小田原<span>（約270m／徒歩4分）</span></p>
              <p className="txt c-justify">小田原地下街「HaRuNe小田原」は「ヒト」「モノ」「コト」が集まる新スタイルのショッピング&コミュニティ空間。魅力的な小田原の「ヒト」「モノ」「コト」を発信し、市民や観光客が集う賑わいの場を創出します。小田原の農水産物、それらの加工品、木製品、地産地消のメニューを提供するレストランなど、魅力的な地域の資源を扱うお店が集結。また、地域の観光・イベント・まちなかの情報を発信する「街かど案内所『小田原日和』」や、イベントを開催する「ハルネ広場」「うめまる広場」を設けています。</p>
            </div>
          </div>
          <div className="flex-box reverse comFeed">
            <div className="img-box">
              <StaticImage
                src="../assets/images/attractiveness/point_2_i_4.jpg"
                alt=""
              />
              <div className="icon">
                <Point2I4Age />
              </div>
            </div>
            <div className="txt-box">
              <p className="ttl">ミナカ小田原<span>（約340m／徒歩5分）</span></p>
              <p className="txt c-justify">江戸情緒薫る「小田原新城下町」と「タワー棟」があり、フード・飲食を中心に多様な40以上の店舗が展開。「西湘フードスタジアム」には小田原・西湘エリアの地元人気飲食店が勢揃いしています。その他、図書館・子育て支援センターをはじめとする行政施設、箱根湯本の天然温泉が愉しめる駅直結のホテル「天成園小田原駅別館」、小田原市街を一望できる展望足湯庭園、最大300名の収納が可能なコンベンションホール、イベントなどが開催される郷土の偉人“二宮金次郎”にちなんだ「金次郎広場」などを併設しています。</p>
            </div>
          </div>
          <div className="flex-box comFeed">
            <div className="img-box">
              <StaticImage
                src="../assets/images/attractiveness/point_2_i_5.jpg"
                alt=""
              />
              <div className="icon">
                <Point2I5Age />
              </div>
            </div>
            <div className="txt-box">
              <p className="ttl">小田原三の丸ホール<span>（約570m／徒歩8分）</span></p>
              <p className="txt c-justify">大ホール・小ホール・展示室・ギャラリー回廊・スタジオ・練習室などからなる、かつての小田原市民会館の歴史を受け継ぐ新しい文化・芸術拠点です。敷地内にはデッキで結ばれた小田原市観光交流センター「CAFÉ SANNOMARU」があり、観光に関する情報やレンタルサイクル、カフェや小田原にまつわる商品などが並べられています。好きな時に来て、好きな場所で好きに過ごして、飽きたら帰って行く。しかも居心地が良い。なんの用事がなくても、何となく足が向いてしまうような、そんな劇場を目指しています。</p>
            </div>
          </div>

        </div>

        <div className="plus-wrap">
          <div className="flex-box flex-box-1 comFeed">
            <div className="txt-box">
              <p className="ttl">「ル・サンク小田原栄町」は<br className='_sp' />地元の人々に<br className='_pc' />愛されてきた<br className='_sp' />オービックビル跡地に誕生。</p>
              <p className="txt">駅からの大通りと銀座通りの交差点に立地するオービックビルは、落語家を招いての寄席や沖縄民謡の屋上イベント、ハロウィーンの仮装イベントを開催するなど、中心市街地の活性化に積極的に取り組み、人々に親しまれつづけてきた商業施設です。残念ながら建物の老朽化に伴い、惜しまれながらも取り壊されることになりました。オービックビルの跡地を得た「ル・サンク小田原栄町」は、小田原の城下町らしい佇まいを継承し、近隣に新しくも美しい景観と魅力を添える建築として誕生します。</p>
            </div>
            <div className="img-box">
              <StaticImage
                src="../assets/images/attractiveness/point_2_i_6.jpg"
                alt=""
              />
              <p className="c-cap">参考写真</p>
            </div>
          </div>
          {/* <div className="flex-box flex-box-2 comFeed">
            <div className="ttl-box">
              <p className="ttl">小田原市が<br className='_sp' />「スーパーシティ構想」に応募。</p>
            </div>
            <div className="txt-box">
              <p className="txt">スーパーシティとは「AIやビッグデータを活用し、社会のあり方を根本から変える未来都市」のこと。スーパーシティ構想とは「各地域の持つ社会的な課題を最先端のテクノロジーによって一挙に解決し、より便利で快適な都市を作ろうという取組」のことを言います。小田原市では未来に向けて市民の暮らしがより一層便利で快適なものとなるよう「デジタル化によるまちづくりの推進」に取り組んでおります。この取組をさらに強力に推進するため、令和3年4月に国が推進する「スーパーシティ構想」に応募しております。小田原が有するポテンシャルに、デジタル技術とデータ活用をスーパーシティ構想に基づき総合的かつ計画的に組み合わせることで、縁ある人すべてを守り、未来を輝かせる「クリーン&スマート城下町“ODAWARA”」の実現を目指しております。</p>
            </div>
          </div> */}
        </div>

      </section>


      <div id="secPoint3"></div>
      <section className='sec-point sec-point-3'>

        <div className="ttl-wrap">
          <div className="ttl-box">
            <p className="subttl">POINT<span>3</span></p>
            <p className="ttl">新旧の彩りある施設が多彩に集積</p>
          </div>
        </div>

        <div className="lead-wrap">
          <div className="lead-box comFeed">
            <p className="ttl-sec">先端のファッションビルはもちろん、<br className='_sp' />老舗有名店や商店街まで、<br />あらゆる商業施設で賑わう東口エリア。</p>
            <p className="lead-sec">古くから商業の中心地である東口エリアは、新しく誕生した話題のスポットばかりでなく、全国的にもその名が知れた老舗有名店なども無数に点在する独自の風土を持つ街。ファッショナブルなショッピングはもちろん、日常のお買い物までが気軽に徒歩圏で済ますことのできる大変恵まれた環境です。</p>
          </div>

          <div className="flex-box comFeed">
            <div className="img-box">
              <StaticImage
                src="../assets/images/attractiveness/point_3_i_1.jpg"
                alt=""
              />
            </div>
            <div className="txt-box">
              <p className="ttl">ラスカ小田原<span>（約370m／徒歩5分）</span></p>
              <p className="txt c-justify">小田原駅東口にある駅ビル。2階は「ミナカ小田原」の金次郎広場、1階は「HaRuNe小田原」と専用通路で結ばれています。ファッション・カルチャー・グルメ・食料品など多彩なショップが充実しており、屋上の「ユメテラス」は季節の植栽で彩った庭園で休憩所としても利用できるほか、さまざまなイベントも開催しています。</p>
            </div>
          </div>
          <div className="flex-box reverse comFeed">
            <div className="img-box">
              <StaticImage
                src="../assets/images/attractiveness/point_3_i_2.jpg"
                alt=""
              />
            </div>
            <div className="txt-box">
              <p className="ttl">OdakyuOX小田原店<span>（約220m／徒歩3分）</span></p>
              <p className="txt c-justify">毎日のお買い物は徒歩3分にある豊富な品揃えが人気のスーパー「Odakyu OX」が便利。バラエティ豊かな商品ラインナップには地元の品も多数あります。また、購入した商品を希望の時間帯に届けてくれる“お買い物お届けサービス”があり、重い物やかさばる物を買ったとき、その後に出かける用事があるときなどは大変重宝します。<br /><span>※配達対象外商品があります。別途配送料がかかります。詳しくは店舗にお問い合わせください。</span></p>
            </div>
          </div>

        </div>





        <AttractivenessMapSlide />












        <div className="shopping-wrap">
          <div className="shopping-box">
            <div className="lead-box comFeed">
              <p className="ttl-sec">歴史探訪しながらお買い物。<br className='_sp' />10もの商店街が身近に。</p>
              <p className="lead-sec">東口エリアにはさまざまな専門店で賑わう10もの商店街があり、人々の暮らしを支えつづけています。城下町の面影を残す佇まいもさることながら、江戸時代以前に創業した茶舗や鞄店、明治・大正時代に始まった書店や生活用品店、昭和初期から商いをつづける青果店など、長い年月を歩んできた店が数多くあります。毎日のお買い物がてらに歴史を感じてみるのも楽しいひとときとなりそうです。</p>
            </div>
            <div className="flex-box">

              <div className="list comFeed">
                <div className="img">
                  <StaticImage
                    src="../assets/images/attractiveness/point_3_i_4.jpg"
                    alt=""
                  />
                  {/* <p className="c-cap">約10m／徒歩1分</p> */}
                </div>
                <p className="ttl">小田原銀座商店会<br /><span>（約10m／徒歩1分）</span></p>
              </div>

              <div className="list comFeed">
                <div className="img">
                  <StaticImage
                    src="../assets/images/attractiveness/point_3_i_6.jpg"
                    alt=""
                  />
                  {/* <p className="c-cap">約10m／徒歩1分</p> */}
                </div>
                <p className="ttl">緑一番街商店会<br /><span>（約10m／徒歩1分）</span></p>
              </div>

              <div className="list comFeed">
                <div className="img">
                  <StaticImage
                    src="../assets/images/attractiveness/point_3_i_12.jpg"
                    alt=""
                  />
                  {/* <p className="c-cap">約20m／徒歩1分</p> */}
                </div>
                <p className="ttl">大工町商店会<br /><span>（約20m／徒歩1分）</span></p>
              </div>

              <div className="list comFeed">
                <div className="img">
                  <StaticImage
                    src="../assets/images/attractiveness/point_3_i_9.jpg"
                    alt=""
                  />
                  {/* <p className="c-cap">約40m／徒歩1分</p> */}
                </div>
                <p className="ttl">ダイヤ街商店会<br /><span>（約40m／徒歩1分）</span></p>
              </div>

              <div className="list comFeed">
                <div className="img">
                  <StaticImage
                    src="../assets/images/attractiveness/point_3_i_10.jpg"
                    alt=""
                  />
                  {/* <p className="c-cap">約50m／徒歩1分</p> */}
                </div>
                <p className="ttl">中央通り商店街<br /><span>（約50m／徒歩1分）</span></p>
              </div>

              <div className="list comFeed">
                <div className="img">
                  <StaticImage
                    src="../assets/images/attractiveness/point_3_i_11.jpg"
                    alt=""
                  />
                  {/* <p className="c-cap">約140m／徒歩2分</p> */}
                </div>
                <p className="ttl">お堀端商店街<br /><span>（約140m／徒歩2分）</span></p>
              </div>

              <div className="list comFeed">
                <div className="img">
                  <StaticImage
                    src="../assets/images/attractiveness/point_3_i_5.jpg"
                    alt=""
                  />
                  {/* <p className="c-cap">約180m／徒歩3分</p> */}
                </div>
                <p className="ttl">小田原錦通り商店街<br /><span>（約180m／徒歩3分）</span></p>
              </div>

              <div className="list comFeed">
                <div className="img">
                  <StaticImage
                    src="../assets/images/attractiveness/point_3_i_7.jpg"
                    alt=""
                  />
                  {/* <p className="c-cap">約200m／徒歩3分</p> */}
                </div>
                <p className="ttl">小田原駅前商店会<br /><span>（約200m／徒歩3分）</span></p>
              </div>

              <div className="list comFeed">
                <div className="img">
                  <StaticImage
                    src="../assets/images/attractiveness/point_3_i_8.jpg"
                    alt=""
                  />
                  {/* <p className="c-cap">約220m／徒歩3分</p> */}
                </div>
                <p className="ttl">駅前おしゃれ横丁商店会<br /><span>（約220m／徒歩3分）</span></p>
              </div>

              <div className="list comFeed">
                <div className="img">
                  <StaticImage
                    src="../assets/images/attractiveness/point_3_i_13.jpg"
                    alt=""
                  />
                  {/* <p className="c-cap">約350m／徒歩5分</p> */}
                </div>
                <p className="ttl">駅前東通り商店会<br /><span>（約350m／徒歩5分）</span></p>
              </div>
            </div>

            <div className="kamaboko-box comFeed">
              <div className="txt-box">
                <p className="ttl">江戸から明治期の風情を再現した<br />「かまぼこ通り」。</p>
                <p className="txt"> 小田原に12軒あるかまぼこ屋本店が軒を構えた「かまぼこ通り」。かつて魚市場だったこともあり、かまぼこ屋だけではなく干物屋・鰹節屋・料亭・飲食店・和菓子屋など30店舗あまりが軒を連ねる通りです。すぐ目の前の海辺を散歩しながら味わうできたての揚げかまぼこは絶品です。</p>
              </div>
              <div className="img-box">
                <div className="img">
                  <StaticImage
                    src="../assets/images/attractiveness/point_3_i_14.jpg"
                    alt=""
                  />
                </div>
                <p className="c-cap">image photo</p>
              </div>
            </div>
          </div>
        </div>

      </section>


      <div id="secPoint4"></div>
      <section className='sec-point sec-point-4'>

        <div className="ttl-wrap">
          <div className="ttl-box">
            <p className="subttl">POINT<span>4</span></p>
            <p className="ttl">自然の豊かさに恵まれた地</p>
          </div>
        </div>

        <div className="lead-wrap">
          <div className="lead-box comFeed">
            <p className="ttl-sec">小田原城址公園や相模湾など、<br />小田原が誇る豊かな自然がここに。</p>
            <p className="lead-sec">東京駅より新幹線で約30分。日帰りできる身近な観光地としても有名な小田原は、風光明媚な自然環境を背景に史跡観光、海の幸、レジャーなど、楽しみが満載。特に駅東口には小田原を代表する小田原城址公園や海があり、観光客ばかりでなく、この街に暮らす方の心のオアシスとなっています。</p>
          </div>

          <div className="nature-box">
            <div className="gallery comFeed">
              <div className="main">
                <div className="img-1">
                  <StaticImage
                    src="../assets/images/attractiveness/point_4_i_1.jpg"
                    alt=""
                  />
                </div>
              </div>
              <div className="sub">
                <div className="img-2">
                  <StaticImage
                    src="../assets/images/attractiveness/point_4_i_2.jpg"
                    alt=""
                  />
                </div>
                <div className="img-3">
                  <StaticImage
                    src="../assets/images/attractiveness/point_4_i_3.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="txt-box comFeed">
              <p className="ttl">小田原城址公園<span>（約390m／徒歩5分）</span></p>
              <p className="txt c-justify">広大な園内は大人も子どもも1日中楽しめるさまざまな施設で構成されています。小田原城の紹介や美術工芸品が展示されている天守閣。常盤木門では甲冑や刀剣などの武具を展示。NINJA館では体験しながら忍者について学ぶことができます。甲冑着付け体験では武者や忍者の着付け体験や、週末には手裏剣打ち体験も行え、他にも年間を通してさまざまなイベントが開催されています。また、公園内では梅・桜・藤・つつじ・花菖蒲・紫陽花・蓮など、年間を通して四季折々の美しい植物が鑑賞でき人々の目を愉しませてくれます。</p>
            </div>
          </div>
          <div className="nature-box">
            <div className="gallery comFeed">
              <div className="main">
                <div className="img-1">
                  <StaticImage
                    src="../assets/images/attractiveness/point_4_i_4.jpg"
                    alt=""
                  />
                </div>
              </div>
              <div className="sub">
                <div className="img-2">
                  <StaticImage
                    src="../assets/images/attractiveness/point_4_i_5.jpg"
                    alt=""
                  />
                </div>
                {/* <div className="img-3">
                  <StaticImage
                    src="../assets/images/attractiveness/point_4_i_6.jpg"
                    alt=""
                  />
                </div> */}
              </div>
            </div>
            <div className="txt-box comFeed">
              <p className="ttl">漁港の駅TOTOCO小田原<span>（約3.1km）</span></p>
              <p className="txt c-justify">2019年にオープンした魚のテーマパーク。魚の宝庫「相模湾」のバラエティに富んだ魚介類を用いた海鮮グルメが味わえるほか、販売コーナーには新鮮な地魚はもちろん、干物やかまぼこなどの水産加工品に加え、地元農産物なども揃っています。眺望テラスからは相模湾の雄大な眺めもお愉しみいただけます。</p>
            </div>
          </div>
          <div className="nature-box">
            <div className="gallery comFeed">
              <div className="main">
                <div className="img-1">
                  <StaticImage
                    src="../assets/images/attractiveness/point_4_i_7.jpg"
                    alt=""
                  />
                </div>
              </div>
              <div className="sub">
                <div className="img-2">
                  <StaticImage
                    src="../assets/images/attractiveness/point_4_i_8.jpg"
                    alt=""
                  />
                </div>
                <div className="img-3">
                  <StaticImage
                    src="../assets/images/attractiveness/point_4_i_9.jpg"
                    alt=""
                  />
                  <p className="c-cap">image photo</p>
                </div>
              </div>
            </div>
            <div className="txt-box comFeed">
              <p className="ttl">小田原さかなセンター<span>（約2.2km）</span></p>
              <p className="txt c-justify">小田原漁港のすぐ隣にある、見て・買って・食べる「市場エンターテイメント」。小田原漁港の隣ならではの、新鮮な魚介を買ってすぐその場で食べられる海鮮バーベキューが大人気。海鮮市場ではその日に水揚げされたばかりの魚介類を販売しています。珍しいお魚も目利きのプロが選んでくれ、調理法なども聞けるので安心です。</p>
            </div>
          </div>

        </div>

      </section>


      <div id="secPoint5"></div>
      <section className='sec-point sec-point-5'>

        <div className="ttl-wrap">
          <div className="ttl-box">
            <p className="subttl">POINT<span>5</span></p>
            <p className="ttl">子育てしやすく暮らしやすい東口</p>
          </div>
        </div>

        <div className="lead-wrap">
          <div className="lead-box comFeed">
            <p className="ttl-sec">お子さまの教育施設も<br className='_sp' />東口に集中しています。</p>
            <p className="lead-sec">通学指定の「三の丸小学校」が徒歩10分。小さなお子さまでも無理なく通える距離にあります。また、保育園は徒歩7分圏内に6カ所、幼稚園が6分圏内に2カ所あり、いずれも駅東口エリアに立地。毎日気軽に送り迎えしてあげられます。</p>
          </div>

          <div className="gallery-box">
            {/* <div className="list l comFeed">
              <div className="img">
                <StaticImage
                  src="../assets/images/attractiveness/point_5_i_1.jpg"
                  alt=""
                />
              </div>
            </div> */}

            <div className="list m comFeed">
              <div className="img">
                <StaticImage
                  src="../assets/images/attractiveness/point_5_i_4.jpg"
                  alt=""
                />
                {/* <p className="c-cap">約30m／徒歩1分</p> */}
              </div>
              <p className="ttl">クーピーガーデン（保育園）<br /><span>（約30m／徒歩1分）</span></p>
            </div>

            <div className="list m comFeed">
              <div className="img">
                <StaticImage
                  src="../assets/images/attractiveness/point_5_i_5.jpg"
                  alt=""
                />
                {/* <p className="c-cap">約300m／徒歩4分</p> */}
              </div>
              <p className="ttl">新玉幼稚園<br /><span>（約300m／徒歩4分）</span></p>
            </div>

            <div className="list s comFeed">
              <div className="img">
                <StaticImage
                  src="../assets/images/attractiveness/point_5_i_6.jpg"
                  alt=""
                />
                {/* <p className="c-cap">約340m／徒歩5分</p> */}
              </div>
              <p className="ttl">おたびよ子育て支援センター<br /><span>（約340m／徒歩5分）</span></p>
            </div>

            <div className="list s comFeed">
              <div className="img">
                <StaticImage
                  src="../assets/images/attractiveness/point_5_i_2.jpg"
                  alt=""
                />
                {/* <p className="c-cap">約800m／徒歩10分</p> */}
              </div>
              <p className="ttl">三の丸小学校<br /><span>（約800m／徒歩10分）</span></p>
            </div>

            <div className="list s comFeed">
              <div className="img">
                <StaticImage
                  src="../assets/images/attractiveness/point_5_i_3.jpg"
                  alt=""
                />
                {/* <p className="c-cap">約930m／徒歩12分</p> */}
              </div>
              <p className="ttl">城山中学校<br /><span>（約930m／徒歩12分）</span></p>
            </div>


          </div>
        </div>

        <div className="relief-wrap">
          <div className="flex-box comFeed">
            <div className="txt-box">
              <p className="ttl">赤ちゃんと一緒のお出かけや<br />お買い物も安心。</p>
              <p className="txt">小田原駅周辺には6の授乳施設と16のおむつ交換場所があります。東口では「アークロード市民窓口」「ラスカ小田原」「HaRuNe小田原」「ミナカ小田原」「おだわら市民交流センター」「おだぴよ子育て支援センター」などに配置されているので赤ちゃんと一緒のお買い物も安心してお出かけいただけます。</p>
            </div>
            {/* <div className="img-box">
              <div className="img">
                <StaticImage
                  src="../assets/images/attractiveness/point_5_i_7.jpg"
                  alt=""
                />
              </div>
            </div> */}
          </div>
        </div>

        <div className="map-wrap">
          <div className="map-box comFeed">
            <div className="img">
              <StaticImage
                src="../assets/images/attractiveness/point_5_i_8.png"
                alt=""
              />
              <p className="note">エリア概念図</p>
            </div>
          </div>
        </div>

        <div className="service-wrap comFeed">
          <div className="service-box comFeed">
            <div className="lead-box">
              <p className="ttl-sec">子育てに手厚い<br className='_sp' />小田原市の行政サービス。</p>
              <p className="lead-sec">小田原市では安心して子育てができる環境づくりを積極的に進めています。</p>
            </div>
            <div className="flex-box">
              <div className="list">
                <div className="ttl-box">
                  <p className="ttl">待機児童解消に向けて<br />保育の受け皿を拡大</p>
                </div>
                <div className="img-box">
                  <div className="img">
                    <StaticImage
                      src="../assets/images/attractiveness/point_5_i_9.jpg"
                      alt=""
                    />
                  </div>
                </div>
                <div className="txt-box">
                  <p className="txt">安心して子育てできる環境づくりの一環として、小規模保育事業や分園の開設など、低年齢児を中心に保育の受け皿確保を進めています。</p>
                </div>
              </div>
              <div className="list">
                <div className="ttl-box">
                  <p className="ttl">子育て世代包括<br />支援センター「はっぴぃ」</p>
                </div>
                <div className="img-box">
                  <div className="img">
                    <StaticImage
                      src="../assets/images/attractiveness/point_5_i_10.jpg"
                      alt=""
                    />
                  </div>
                </div>
                <div className="txt-box">
                  <p className="txt">妊娠期から子育て期を切れ目なくサポートするための拠点として開設されました。母子保健相談支援専門員（助産師）・保健師が、妊娠・出産・子育てに関する相談を受け付けています。</p>
                </div>
              </div>
              <div className="list">
                <div className="ttl-box">
                  <p className="ttl">支えあう心「ファミリー・
                    サポート・センター」</p>
                </div>
                <div className="img-box">
                  <div className="img">
                    <StaticImage
                      src="../assets/images/attractiveness/point_5_i_11.jpg"
                      alt=""
                    />
                  </div>
                </div>
                <div className="txt-box">
                  <p className="txt">地域ぐるみで支えあう子育ての実現に向けた活動のひとつ。子どもの送迎や一時預かりをお願いしたい方（依頼会員）と、子どもを預かってくれる方（支援会員）を橋渡ししています。</p>
                </div>
              </div>
              <div className="list">
                <div className="ttl-box">
                  <p className="ttl">子育ち親育ちを応援<br />「子育て支援センター」</p>
                </div>
                <div className="img-box">
                  <div className="img">
                    <StaticImage
                      src="../assets/images/attractiveness/point_5_i_12.jpg"
                      alt=""
                    />
                  </div>
                </div>
                <div className="txt-box">
                  <p className="txt">地域で安心して楽しく子育てをするためのサポートをしています。育児相談・育児情報や親子で交流する場の提供、講座・イベントの開催を行っています。</p>
                </div>
              </div>
              <div className="list">
                <div className="ttl-box">
                  <p className="ttl">保育コンシェルジュが<br />サポート</p>
                </div>
                <div className="img-box">
                  <div className="img">
                    <StaticImage
                      src="../assets/images/attractiveness/point_5_i_13.jpg"
                      alt=""
                    />
                  </div>
                </div>
                <div className="txt-box">
                  <p className="txt">それぞれの家庭のライフスタイルに適した保育サービスのアドバイスなどを行います。保育園などの入所に関する相談のほか、保育サービスにかかる様々な情報をお伝えします。</p>
                </div>
              </div>
              <div className="list">
                <div className="ttl-box">
                  <p className="ttl">小児医療費助成制度<br />（通院・入院）</p>
                </div>
                <div className="img-box">
                  <div className="img">
                    <StaticImage
                      src="../assets/images/attractiveness/point_5_i_14.jpg"
                      alt=""
                    />
                  </div>
                </div>
                <div className="txt-box">
                  <p className="txt">0歳〜中学生のお子さまが医療機関で保険診療を受けたとき、その自己負担額を助成します。 ※1</p>
                </div>
              </div>
              <div className="list">
                <div className="ttl-box">
                  <p className="ttl">出産育児一時金</p>
                </div>
                <div className="img-box">
                  <div className="img">
                    <StaticImage
                      src="../assets/images/attractiveness/point_5_i_15.jpg"
                      alt=""
                    />
                  </div>
                </div>
                <div className="txt-box">
                  <p className="txt">健康保険に加入している方が出産したとき、出産児1人につき42万円が支給されます。</p>
                </div>
              </div>
              <div className="list">
                <div className="ttl-box">
                  <p className="ttl">児童手当</p>
                </div>
                <div className="img-box">
                  <div className="img">
                    <StaticImage
                      src="../assets/images/attractiveness/point_5_i_16.jpg"
                      alt=""
                    />
                  </div>
                </div>
                <div className="txt-box">
                  <p className="txt">出生から中学校卒業まで（15歳の誕生日後の最初の3月31日まで）の児童を養育している方に毎月手当が支給されます。※2 ※3 ※4</p>
                </div>
              </div>
            </div>
          </div>
          <div className="note-box">
            <p>※1.小・中学生は保護者の所得による所得制限があります。<br />※2.所得制限があります。<br />・3歳未満 一律15,000円<br />・3歳以上小学校修了前 一律10,000円（第3子以降は15,000円）<br />・中学生 一律10,000円<br />※3.児童を養育している方の所得が所得制限限度額以上の場合は、特例給付として月額一律5,000円が支給されます。<br />※4.詳しくは小田原市役所子育て政策課手当・医療係へお問い合わせください。</p>
          </div>
        </div>

      </section>


      <section className='sec-lifeinfo'>
        <div className="lifeinfo-wrap">
          <div className="ttl-box comFeed">
            <p className="subttl">周辺環境</p>
            <p className="ttl">LIFE INFORMATION</p>
          </div>

          <div className="map-box comFeed">
            <StaticImage
              src="../assets/images/attractiveness/life-1.png"
              alt=""
            />
          </div>

          {/* <div className="gallery-box">
            <div className="list comFeed">
              <div className="img">
                <StaticImage
                  src="../assets/images/attractiveness/life-2.jpg"
                  alt=""
                />
              </div>
              <p className="txt">小田原銀座商店街<br />（約10m／徒歩1分）</p>
            </div>
            <div className="list comFeed">
              <div className="img">
                <StaticImage
                  src="../assets/images/attractiveness/life-3.jpg"
                  alt=""
                />
              </div>
              <p className="txt">OdakyuOX小田原店<br />（約220m／徒歩3分）</p>
            </div>
            <div className="list comFeed">
              <div className="img">
                <StaticImage
                  src="../assets/images/attractiveness/life-4.jpg"
                  alt=""
                />
              </div>
              <p className="txt">ダイヤ街商店会<br />（約40m／徒歩1分）</p>
            </div>
            <div className="list comFeed">
              <div className="img">
                <StaticImage
                  src="../assets/images/attractiveness/life-5.jpg"
                  alt=""
                />
              </div>
              <p className="txt">小田原お堀端万葉の湯<br />（約160m／徒歩2分）</p>
            </div>
            <div className="list comFeed">
              <div className="img">
                <StaticImage
                  src="../assets/images/attractiveness/life-6.jpg"
                  alt=""
                />
              </div>
              <p className="txt">小田原駅東口図書館<br />（約340m／徒歩5分）</p>
            </div>
            <div className="list comFeed">
              <div className="img">
                <StaticImage
                  src="../assets/images/attractiveness/life-7.jpg"
                  alt=""
                />
              </div>
              <p className="txt">新玉幼稚園<br />（約300m／徒歩4分）</p>
            </div>
          </div> */}

          <div className="list-box comFeed">
            <div className="list">
              <p className="ttl">◆商店街</p>
              <ul>
                <li>
                  <p>小田原銀座商店会</p>
                  <p>（約10m／徒歩1分）</p>
                </li>
                <li>
                  <p>緑一番街商店会</p>
                  <p>（約10m／徒歩1分）</p>
                </li>
                <li>
                  <p>ダイヤ街商店会</p>
                  <p>（約40m／徒歩1分）</p>
                </li>
                <li>
                  <p>大工町商店会</p>
                  <p>（約20m／徒歩1分）</p>
                </li>
                <li>
                  <p>中央通り商店街</p>
                  <p>（約50m／徒歩1分）</p>
                </li>
                <li>
                  <p>お堀端商店街</p>
                  <p>（約140m／徒歩2分）</p>
                </li>
                <li>
                  <p>小田原駅前商店会</p>
                  <p>（約200m／徒歩3分）</p>
                </li>
                <li>
                  <p>駅前おしゃれ横丁商店会</p>
                  <p>（約220m／徒歩3分）</p>
                </li>
                <li>
                  <p>駅前東通り商店会</p>
                  <p>（約350m／徒歩5分）</p>
                </li>
              </ul>
            </div>
            <div className="list">
              <p className="ttl">◆商業施設</p>
              <ul>
                <li>
                  <p>サンドラッグ小田原店</p>
                  <p>（約100m／徒歩2分）</p>
                </li>
                <li>
                  <p>ドン・キホーテ小田原店</p>
                  <p>（約140m／徒歩2分）</p>
                </li>
                <li>
                  <p>OdakyuOX小田原店</p>
                  <p>（約220m／徒歩3分）</p>
                </li>
                <li>
                  <p>トザンイースト</p>
                  <p>（約270m／徒歩4分）</p>
                </li>
                <li>
                  <p>マツモトキヨシ小田原駅東口店</p>
                  <p>（約280m／徒歩4分）</p>
                </li>
                <li>
                  <p>小田原駅東口・ミナカ小田原</p>
                  <p>（約340m／徒歩5分）</p>
                </li>
                <li>
                  <p>ローソンミナカ小田原店</p>
                  <p>（約340m／徒歩5分）</p>
                </li>
              </ul>
            </div>
            <div className="list">
              <p className="ttl">◆公共施設</p>
              <ul>
                <li>
                  <p>小田原駅東口図書館</p>
                  <p>（約340m／徒歩5分）</p>
                </li>
                <li>
                  <p>小田原三の丸ホール</p>
                  <p>（約570m／徒歩8分）</p>
                </li>
                <li>
                  <p>小田原市観光交流センター</p>
                  <p>（約570m／徒歩8分）</p>
                </li>
                <li>
                  <p>小田原市役所</p>
                  <p>（約1,450m／徒歩19分）</p>
                </li>
              </ul>
            </div>
            <div className="list">
              <p className="ttl">◆文化・レジャー施設</p>
              <ul>
                <li>
                  <p>小田原お堀端万葉の湯</p>
                  <p>（約160m／徒歩2分）</p>
                </li>
                <li>
                  <p>小田原城址公園</p>
                  <p>（約390m／徒歩5分）</p>
                </li>
                <li>
                  <p>小田原城歴史見聞館NINJA館</p>
                  <p>（約640m／徒歩8分）</p>
                </li>
                <li>
                  <p>郷土文化館</p>
                  <p>（約750m／徒歩10分）</p>
                </li>
                <li>
                  <p>報徳二宮神社</p>
                  <p>（約950m／徒歩12分）</p>
                </li>
                <li>
                  <p>小田原城址公園こども遊園地</p>
                  <p>（約950m／徒歩12分）</p>
                </li>
              </ul>
            </div>
            <div className="list">
              <p className="ttl">◆教育施設</p>
              <ul>
                <li>
                  <p>新玉幼稚園</p>
                  <p>（約300m／徒歩4分）</p>
                </li>
                <li>
                  <p>三の丸小学校</p>
                  <p>（約800m／徒歩10分）</p>
                </li>
                <li>
                  <p>城山中学校</p>
                  <p>（約930m／徒歩12分）</p>
                </li>
              </ul>
            </div>
          </div>
        </div>


        <div className="c-content">
          <div className="com-note-wrap">
            <p className="c-note">※掲載の環境写真は、2021年10月に撮影したものです。<br />※掲載の距離表示については地図上の概測距離を、徒歩分数表示については80m＝1分として算出し、端数を切り上げしたものです。<br />※掲載の情報は 2022年1月時点のものです。<br />※掲載のエリア概念図は、現地周辺地図を基に概念的に描き起こしたもので位置関係・建物形状・色調等、実際とは異なります。予めご了承ください。<br />※「image photo」の表記がある写真は全てイメージです。</p>
          </div>
        </div>

      </section>


      {/* CONTENTS */}
    </main>
  )
}

export default AttractivenessPage
